<template>
  <Modal @close="$emit('close')" classes="p-4 sm:p-6">
    <div class="flex flex-col h-full">
      <h1 class="heading font-bold mb-6">Contact Keyword Search.</h1>
      <form v-if="!submitted" @submit.prevent="handleSubmit()">
        <InputField
          v-model:value="titles"
          label="Contact Title contains"
          description="Search multiple possibilities by using comma between options. Not case sensitive."
          placeholder="Eg. Director, Head of"
          class="mb-8"
        />
        <InputField
          v-model:value="names"
          label="Contact Name equals"
          description="Search multiple possibilities by using comma between options. Case sensitive."
          placeholder="Eg. Jane Smith, John Doe"
          class="mb-8"
        />
        <InputField
          v-model:value="companies"
          label="Company Name equals"
          description="Search multiple possibilities by using comma between options. Case sensitive."
          placeholder="Eg. Notion, Roblox, Uber"
          class="mb-8"
        />
        <InputField
          v-model:value="gtmListsInLast2Months"
          label="Max number of intro requests in Last 2 Months"
          description="Filter by maximum number of intro requests this contact has already received in the Last 2 Months."
          placeholder="Eg. 0, 1, 2, 3"
          class="mb-8"
        />
        <div class="flex flex-col items-start mb-2 w-full mb-8">
          <label class="input--label font-bold">Location</label>
          <VueMultiselect
            v-model="selectedLocations"
            :options="locations"
            :multiple="true"
            :searchable="true"
            placeholder="Search and select locations"
            class="w-full"
          />
        </div>
        <div class="flex flex-col items-start mb-2 w-full mb-8">
          <label class="input--label font-bold">Company Employee Size</label>
          <VueMultiselect
            v-model="selectedEmployeeCountRanges"
            :options="employeeCountRanges"
            :multiple="true"
            :searchable="false"
            placeholder="Select employee count ranges"
            class="w-full"
          />
        </div>
        <div class="flex flex-col items-start mb-8 w-full">
          <label class="input--label font-bold"> Date Inbound Email last received </label>
          <p class="text-xs">Filter by last date we received an email from the contact.</p>
          <input
            v-model="lastInboundEmail"
            type="date"
            class="border text-sm rounded-lg w-full ps-10 p-2.5"
            placeholder="Select date"
          />
        </div>
        <div class="flex justify-end mt-10">
          <Button type="submit">Search</Button>
        </div>
      </form>
      <template v-else>
        <LoadingSpinner v-if="resultsLoading" />
        <template v-else>
          <div v-if="resultsCollection.list.length" class="w-full flex flex-row items-center mt-8">
            <p>
              <span class="font-bold">{{ checkedIds.length }}</span> selected.
            </p>
            <Button class="ml-auto" :disabled="!checkedIds.length" @click.prevent="handleSelect()">
              {{ selectCTACopy }}
            </Button>
          </div>
          <div class="overflow-x-auto">
            <table
              v-if="resultsCollection.list.length"
              class="w-full table-auto border-collapse border-0 border-slate-400 text-sm mt-7 mb-7"
            >
              <thead>
                <tr>
                  <th v-if="resultsCollection.list.length" class="w-8" />
                  <th class="border border-slate-300">Name</th>
                  <th class="border border-slate-300">Title</th>
                  <th class="border border-slate-300">Company Name</th>
                  <th class="border border-slate-300">
                    Email Address
                    <Popper arrow placement="left-end">
                      <InformationCircleIcon class="h-5 w-5 cursor-pointer" />
                      <template #content>
                        <div
                          class="bg-white rounded-lg p-5 border max-w-prose font-normal text-left"
                        >
                          May be missing (from Salesforce).
                        </div>
                      </template>
                    </Popper>
                  </th>
                  <th class="border border-slate-300">Warmth to FRC</th>
                  <th class="border border-slate-300">Warmest Contact</th>
                  <th class="border border-slate-300">Last <br />Inbound</th>
                  <th class="border border-slate-300">
                    Last <br />
                    Outbound
                  </th>
                  <th class="border border-slate-300">Contacts</th>
                  <th class="border border-slate-300">At Portfolio Company?</th>
                  <th class="border border-slate-300 text-xs">Intro Requests in Last 2 Months</th>
                  <th class="border border-slate-300">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contact in resultsCollection.list" :key="contact.id" class="h-10">
                  <td>
                    <CheckboxField v-model:value="checkboxes[contact.id]" />
                  </td>
                  <td class="border border-slate-300 px-2">
                    <a
                      v-if="contact.linkedinProfile"
                      :href="contact.linkedinProfile"
                      target="_blank"
                      class="text-cyan-500"
                    >
                      {{ contact.name }}
                    </a>
                    <template v-else>{{ contact.name }}</template>
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.title }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.company?.name || 'N/A' }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.email }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.warmth }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.closestInternalEmail }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{
                      contact.lastInboundMoment !== null
                        ? contact.lastInboundMoment.format('MM/DD/YYYY')
                        : 'N/A'
                    }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{
                      contact.lastOutboundMoment !== null
                        ? contact.lastOutboundMoment.format('MM/DD/YYYY')
                        : 'N/A'
                    }}
                  </td>
                  <td class="border border-slate-300 px-2 max-w-xs">
                    {{ contact.mailboxes }}
                  </td>
                  <td class="border border-slate-300 px-2 max-w-xs">
                    {{
                      contact.company?.isPortfolioCompany
                        ? 'Yes (' + contact.company?.portfolioPointContactName + ')'
                        : 'No'
                    }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.gtmListsInLast2Months || '0' }}
                  </td>
                  <td class="border border-slate-300 px-2">
                    {{ contact.location }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="py-8">No results.</div>
          </div>
        </template>
        <div
          class="flex flex-col items-center"
          :class="{ hidden: resultsLoading || !resultsCollection.list.length }"
        >
          <CollectionPagination
            :collection="resultsCollection"
            @is-loading-status-update="(isLoading) => (resultsLoading = isLoading)"
          />
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import Contact, { constants } from '@/services/contacts'

import { InformationCircleIcon } from '@heroicons/vue/24/solid'

import LoadingSpinner from '@/components/LoadingSpinner'
import Modal from '@/components/modals/Modal'
import InputField from '@/components/inputs/InputField'
import Button from '@/components/inputs/Button'
import CheckboxField from '@/components/inputs/CheckboxField'
import CollectionPagination from '@/components/inputs/CollectionPagination'
import VueMultiselect from 'vue-multiselect'

/**
 * If the object property does not exist, return false
 */
const checkboxDataHandler = {
  get: function (target, name) {
    // eslint-disable-next-line no-prototype-builtins
    return target.hasOwnProperty(name) ? target[name] : false
  },
}

export default {
  name: 'ContactKeywordSearchModal',
  components: {
    InformationCircleIcon,
    LoadingSpinner,
    Modal,
    InputField,
    Button,
    CheckboxField,
    CollectionPagination,
    VueMultiselect,
  },
  props: {
    selectCTACopy: {
      type: String,
      default: 'Select',
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // meta
      submitted: false,
      // form -related
      names: '',
      titles: '',
      companies: '',
      warmth: [],
      selectedLocations: [],
      locations: constants.LOCATION_OPTIONS,
      employeeCountRanges: constants.COMPANY_EMPLOYEE_COUNT_OPTIONS.map((option) => option.value),
      selectedEmployeeCountRanges: [],
      lastInboundEmail: '',
      gtmListsInLast2Months: '',
      // results -related
      resultsCollection: null,
      resultsLoading: false,
      checkboxes: new Proxy({}, checkboxDataHandler),
    }
  },
  methods: {
    async handleSubmit() {
      this.resultsLoading = true
      this.submitted = true

      const filters = {
        ordering: 'name',
      }

      if (this.excludeIds.length) {
        filters.exclude = this.excludeIds.join(',')
      }

      if (this.names.length) {
        filters.name = this.names
      }
      if (this.titles.length) {
        filters.title = this.titles
      }
      if (this.companies.length) {
        filters.companyName = this.companies
      }
      if (this.lastInboundEmail.length) {
        filters.lastInboundEmail = this.lastInboundEmail
      }
      if (this.gtmListsInLast2Months.length) {
        filters.gtmListsInLast2Months = this.gtmListsInLast2Months.toString()
      }
      if (this.selectedLocations.length) {
        // Extract the string values correctly
        // Use pipe because options include commas
        filters.location = this.selectedLocations.join('|')
      }
      if (this.selectedEmployeeCountRanges.length) {
        // Get the order mapping from COMPANY_EMPLOYEE_COUNT_OPTIONS
        const orderMap = constants.COMPANY_EMPLOYEE_COUNT_OPTIONS.reduce((map, obj) => {
          map[obj.value] = obj.order
          return map
        }, {})

        // Sort the selected ranges based on their order in COMPANY_EMPLOYEE_COUNT_OPTIONS
        const sortedRanges = this.selectedEmployeeCountRanges.sort((a, b) => {
          return orderMap[a] - orderMap[b]
        })

        // Join the sorted ranges with '|' and assign to the filter
        filters.companyEmployeeCountRanges = sortedRanges.join('|')
      }

      this.resultsCollection = Contact.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters,
      })

      await this.resultsCollection.refresh()
      this.resultsLoading = false
    },
    handleSelect() {
      this.$emit('select', this.checkedIds)
    },
  },
  computed: {
    checkedIds() {
      return Object.entries(this.checkboxes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
    },
  },
  watch: {
    excludeIds() {
      // only proceed if the excluded ids are 'fresher' than the resuls collection
      if (!this.resultsCollection) return
      if (!this.excludeIds.length) return
      if (this.resultsCollection.filters.exclude === this.excludeIds.join(',')) return

      // when new exclusions are added
      // ensure those ids are not tracked in checkbox state
      this.excludeIds.forEach((id) => {
        delete this.checkboxes[id]
      })

      // when new exclusions are added
      // ensure those contacts are not in the results collection
      this.resultsCollection.filters.exclude = this.excludeIds.join(',')
      this.resultsCollection.refresh()
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
