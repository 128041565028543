<template>
  <div class="max-w-screen-xl w-full flex flex-col px-4 mx-auto">
    <LoadingSpinner v-if="loading" />
    <template v-else>
      <DefaultBanner v-if="list.sentToSalesforce" class="mb-8">
        This list has been sent to Salesforce.
      </DefaultBanner>
      <h1 class="relative heading mt-6 mb-12">
        Build Customer List.
        <Button
          class="absolute top-0 right-0"
          primary
          :disabled="list.sentToSalesforce || !listMembers.list.length"
          @click.prevent="attemptSendListToSFDC()"
        >
          Send To SFDC
        </Button>
      </h1>
      <div class="max-w-sm">
        <InternalCustomerListForm
          :internalCustomerList="list"
          @submit="attemptListUpdate"
          :disabled="list.sentToSalesforce || attemptingUpdate"
        />
      </div>
      <div class="w-full flex flex-row mt-8">
        <div class="ml-auto">
          <Button
            class="mx-2"
            :disabled="list.sentToSalesforce || !checkedIds.length"
            @click.prevent="attemptDeleteListMembers(checkedIds)"
          >
            Remove
          </Button>
          <Button
            class="mx-2"
            @click.prevent="showContactKeywordSearchModal = true"
            :disabled="list.sentToSalesforce"
          >
            + Add
          </Button>
        </div>
      </div>
      <table class="w-full table-auto border-collapse border-0 border-slate-400 text-sm mt-7 mb-7">
        <thead>
          <tr>
            <th v-if="listMembers.list.length" class="w-8">
              <CheckboxField v-model:value="checkAll" :disabled="list.sentToSalesforce" />
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__name')">
              Name
              <span v-if="currentOrdering === 'contact__name'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-contact__name'" class="sort-indicator">▼</span>
              <span
                v-if="currentOrdering !== '-contact__name' && currentOrdering !== 'contact__name'"
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__title')">
              Title
              <span v-if="currentOrdering === 'contact__title'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-contact__title'" class="sort-indicator">▼</span>
              <span
                v-if="currentOrdering !== '-contact__title' && currentOrdering !== 'contact__title'"
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__company__name')">
              Company Name
              <span v-if="currentOrdering === 'contact__company__name'" class="sort-indicator"
                >▲</span
              >
              <span v-if="currentOrdering === '-contact__company__name'" class="sort-indicator"
                >▼</span
              >
              <span
                v-if="
                  currentOrdering !== '-contact__company__name' &&
                  currentOrdering !== 'contact__company__name'
                "
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300">
              Email Address
              <Popper arrow placement="left-end">
                <InformationCircleIcon class="h-5 w-5 cursor-pointer" />
                <template #content>
                  <div class="bg-white rounded-lg p-5 border max-w-prose font-normal text-left">
                    May be missing (from Salesforce).
                  </div>
                </template>
              </Popper>
            </th>
            <th
              class="border border-slate-300"
              @click="updateOrdering('contact__sigparser_closest_internal_email')"
            >
              Warmest Contact
              <span
                v-if="currentOrdering === 'contact__sigparser_closest_internal_email'"
                class="sort-indicator"
                >▲</span
              >
              <span
                v-if="currentOrdering === '-contact__sigparser_closest_internal_email'"
                class="sort-indicator"
                >▼</span
              >
              <span
                v-if="
                  currentOrdering !== '-contact__sigparser_closest_internal_email' &&
                  currentOrdering !== 'contact__sigparser_closest_internal_email'
                "
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__warmth_score')">
              Warmth to FRC
              <span v-if="currentOrdering === 'contact__warmth_score'" class="sort-indicator"
                >▲</span
              >
              <span v-if="currentOrdering === '-contact__warmth_score'" class="sort-indicator"
                >▼</span
              >
              <span
                v-if="
                  currentOrdering !== '-contact__warmth_score' &&
                  currentOrdering !== 'contact__warmth_score'
                "
                >▲▼</span
              >
            </th>
            <th
              class="border border-slate-300 text-xs"
              @click="updateOrdering('contact__sigparser_last_inbound_email')"
            >
              Last Inbound to FRC
              <span
                v-if="currentOrdering === 'contact__sigparser_last_inbound_email'"
                class="sort-indicator"
                >▲</span
              >
              <span
                v-if="currentOrdering === '-contact__sigparser_last_inbound_email'"
                class="sort-indicator"
                >▼</span
              >
              <span
                v-if="
                  currentOrdering !== '-contact__sigparser_last_inbound_email' &&
                  currentOrdering !== 'contact__sigparser_last_inbound_email'
                "
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300">Contacts</th>
            <th class="border border-slate-300">At Portfolio Company?</th>
            <th class="border border-slate-300 text-xs">Intro Requests in Last 2 Months</th>
            <th class="border border-slate-300" @click="updateOrdering('datetime_created')">
              Date Added
              <span v-if="currentOrdering === 'datetime_created'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-datetime_created'" class="sort-indicator">▼</span>
              <span
                v-if="
                  currentOrdering !== '-datetime_created' && currentOrdering !== 'datetime_created'
                "
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__location')">
              Location
              <span v-if="currentOrdering === 'contact__location'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-contact__location'" class="sort-indicator">▼</span>
              <span
                v-if="
                  currentOrdering !== '-contact__location' &&
                  currentOrdering !== 'contact__location'
                "
                >▲▼</span
              >
            </th>
          </tr>
        </thead>
        <LoadingSpinner v-if="listMembers.refreshing" />
        <tbody v-else>
          <tr v-for="listMember in listMembers.list" :key="listMember.id" class="h-10">
            <td>
              <CheckboxField
                v-model:value="checkboxes[listMember.id]"
                :disabled="list.sentToSalesforce"
              />
            </td>
            <td class="border border-slate-300 px-2">
              <a
                v-if="listMember.contact.linkedinProfile"
                :href="listMember.contact.linkedinProfile"
                target="_blank"
                class="text-cyan-500"
              >
                {{ listMember.contact.name }}
              </a>
              <template v-else>{{ listMember.contact.name }}</template>
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.title }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.company?.name || 'N/A' }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.email }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.closestInternalEmail }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.warmth }}
            </td>
            <td class="border border-slate-300 px-2">
              {{
                listMember.contact.lastOutboundMoment !== null
                  ? listMember.contact.lastOutboundMoment.format('MM/DD/YYYY')
                  : 'N/A'
              }}
            </td>
            <td class="border border-slate-300 p-2 max-w-xs">
              {{ listMember.contact.mailboxes }}
            </td>
            <td class="border border-slate-300 px-2 max-w-xs">
              {{
                listMember.contact.company?.isPortfolioCompany
                  ? 'Yes (' + listMember.contact.company?.portfolioPointContactName + ')'
                  : 'No'
              }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.gtmListsInLast2Months || '0' }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.datetimeCreatedMoment.format('MM/DD/YYYY') }}
            </td>
            <td class="border border-slate-300 px-2">
              {{ listMember.contact.location }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!listMembers.list.length">No List Members.</div>
    </template>
  </div>
  <ContactKeywordSearchModal
    v-if="showContactKeywordSearchModal"
    @close="showContactKeywordSearchModal = false"
    @select="attemptCreateListMembers"
    :excludeIds="listMembers.list.map((lm) => lm.contact.id)"
    selectCTACopy="Add to List"
  />
</template>

<script>
import InternalCustomerList from '@/services/internalCustomerLists'
import InternalCustomerListMember from '@/services/internalCustomerListMembers'
import { successToast } from '@/services/toastify'

import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import LoadingSpinner from '@/components/LoadingSpinner'
import CheckboxField from '@/components/inputs/CheckboxField'
import Button from '@/components/inputs/Button'
import ContactKeywordSearchModal from '@/components/modals/ContactKeywordSearchModal'
import InternalCustomerListForm from '@/components/InternalCustomerListForm'
import DefaultBanner from '@/components/banners/DefaultBanner'

/**
 * If the object property does not exist, return false
 */
const checkboxDataHandler = {
  get: function (target, name) {
    // eslint-disable-next-line no-prototype-builtins
    return target.hasOwnProperty(name) ? target[name] : false
  },
}

export default {
  name: 'InternalCustomerListEdit',
  components: {
    LoadingSpinner,
    DefaultBanner,
    CheckboxField,
    InformationCircleIcon,
    Button,
    ContactKeywordSearchModal,
    InternalCustomerListForm,
  },
  data() {
    return {
      list: null,
      loading: true,
      attemptingUpdate: false,
      listMembers: InternalCustomerListMember.createCollection({
        filters: {
          ordering: this.$route.query.ordering || '-datetime_created',
          internalCustomerListId: this.$route.params.id,
        },
      }),
      checkboxes: new Proxy({}, checkboxDataHandler),
      checkAll: false,
      showContactKeywordSearchModal: false,
    }
  },
  async created() {
    try {
      const list = await InternalCustomerList.api.retrieve(this.$route.params.id)
      this.list = list
    } catch (e) {
      console.error('Error retrieving the customer list:', e)
      this.$router.push({ name: 'PageNotFound' })
    }

    try {
      await this.listMembers.refresh()
      console.log(this.listMembers.list) // Log the data here
      this.loading = false
    } catch (e) {
      console.error('Error refreshing list members:', e)
    }
  },
  methods: {
    attemptSendListToSFDC() {
      const confirmation = confirm(
        'Confirm action: Send Customer List to SFDC.\nThis cannot be undone.',
      )
      if (!confirmation) return

      InternalCustomerList.api.createInSalesforce(this.list.id).then(() => window.location.reload())
    },
    attemptListUpdate(name, type, contactId, companyId, autoSetSendToAppInSalesforce = true) {
      this.attemptingUpdate = true
      InternalCustomerList.api
        .update(this.list.id, { name, type, contactId, companyId, autoSetSendToAppInSalesforce })
        .then((list) => {
          this.list = list
          this.attemptingUpdate = false
          successToast(`Customer List fields updated.`)
        })
    },
    attemptCreateListMembers(contactIds) {
      InternalCustomerList.api.createListMembers(this.list.id, contactIds).then((listMembers) => {
        this.listMembers.list = [...listMembers, ...this.listMembers.list]
        if (contactIds.length > 1) {
          successToast(`${contactIds.length} Contacts added to Customer List.`)
        } else {
          successToast(`1 Contact added to Customer List.`)
        }
      })
    },
    attemptDeleteListMembers(listMemberIds) {
      if (listMemberIds.length > 50) {
        alert('Limit of 50 items per action.\nPlease select fewer items.')
        return
      }
      InternalCustomerListMember.api.bulkDelete(listMemberIds).then(() => {
        // remove checked from list
        this.listMembers.refresh()

        // clear checkboxes
        if (this.checkAll) {
          // checkAll watcher will clear checkboxes
          this.checkAll = false
        } else {
          // clear checkboxes manually
          this.checkboxes = new Proxy({}, checkboxDataHandler)
        }

        // notify success
        successToast(`${listMemberIds.length} Contacts removed from Customer List.`)
      })
    },
    updateOrdering(ordering) {
      let currentOrdering = this.$route.query.ordering || '-datetime_created'
      if (currentOrdering === ordering) {
        ordering = '-' + ordering
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          ordering: ordering,
        },
      })
    },
    setQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ordering: this.listMembers.filters.ordering,
        },
      })
    },
  },
  computed: {
    checkedIds() {
      return Object.entries(this.checkboxes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
    },
    currentOrdering() {
      return this.$route.query.ordering || '-datetime_created'
    },
  },
  watch: {
    checkAll(value) {
      const newCheckboxes = new Proxy({}, checkboxDataHandler)
      if (!value) {
        this.checkboxes = newCheckboxes
        return
      }

      this.listMembers.list.forEach((lm) => {
        newCheckboxes[lm.id] = true
      })

      this.checkboxes = newCheckboxes
    },
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.loading = true
        this.listMembers.filters.ordering = this.$route.query.ordering || '-datetime_created'
        await this.listMembers.refresh()
        this.loading = false
      },
    },
    'listMembers.filters.ordering': {
      immediate: true,
      deep: true,
      handler() {
        this.setQueryParams()
      },
    },
  },
}
</script>
